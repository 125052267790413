import { UncontrolledTreeEnvironment, Tree, StaticTreeDataProvider } from 'react-complex-tree';
import 'react-complex-tree/lib/style-modern.css'; // Import the default styles for react-complex-tree
import { RiFolderAddLine, RiFolderLine, RiDeleteBin6Line, RiFileAddLine, RiEdit2Line, RiFolderLine as RiFolder, RiArrowDownSLine } from "react-icons/ri";
import { AiOutlineFileAdd, AiOutlineFile } from "react-icons/ai";
import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Modal, IconButton, Box, CircularProgress } from '@mui/material';
import ReactDOM from 'react-dom'; // For using React Portal
import ToastMessage from '../commonComponents/toastMessage'
import * as Constants from '../../common/constants'
import utils from '../../common/utils';
import { RiDeleteBin6Fill } from "react-icons/ri";
import ConfirmationModal from '../commonComponents/conformationModal';


const FileExpolerSideBar = (props) => {
    const [items, setItems] = useState(props?.fileTreeViewjson);
    const [contextMenu, setContextMenu] = useState(null); // For managing the context menu state
    const [renameItemId, setRenameItemId] = useState(null); // To manage renaming
    const [renameValue, setRenameValue] = useState(''); // For rename input
    const [showModal, setShowModal] = useState(false); // For managing the modal
    const [toastMsg, setToastMsg] = useState({ type: '', msg: '' });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isNewFileFolder,setIsNewFileFolder] = useState(false);

    // Function to add a new folder to the root or a folder
    const addFolder = (parentId = 'root') => {
        const newItems = { ...items };
    
        const generateUniqueId = () => {
            return Math.floor(1000 + Math.random() * 9000).toString();
        };
    
        const getUniqueId = () => {
            let uniqueId = generateUniqueId();
            // Check if the generated ID already exists
            while (newItems[uniqueId]) {
                uniqueId = generateUniqueId();
            }
            return uniqueId;
        };
    
        const newFolderId = getUniqueId(); // Get a unique 4-digit ID
    
        if (!newItems['root']) {
            newItems['root'] = {
                index: 'root',
                isFolder: true,
                type: 'folder',
                children: [],
                data: 'Root',
            };
        }

         newItems[parentId].children.push(newFolderId);
        newItems[newFolderId] = {
            index: newFolderId,
            isFolder: true,
            type: 'folder',
            children: [],
            data: `New Folder ${newFolderId}`,
        };

        fetch(Constants.APIS.CREATE_DOCUMENTATION_EDITOR_FILE, {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                email: props.userData.email,
                accessToken: props.userData.accessToken,
                documentationProjectId: props.documentationProjectId,
                fileTreeViewjson: JSON.stringify(newItems),
                isFolder:'Y'
            })
        })
        .then(response => response.json())
        .then(data => {
            if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                utils.deleteWebSession();
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                setItems(newItems);
                props?.setFileTreeViewJson(newItems);
                // setIdCounter(idCounter + 1); // This might not be needed anymore
                setContextMenu(null); // Hide the context menu after adding
            } else {
                setToastMsg({ type: 'error', msg: 'An error occurred while creating the File. Please try again.' });
                setSnackbarOpen(true);
            }
        })
        .catch(err => {
            setToastMsg({ type: 'error', msg: 'An error occurred while creating the File. Please try again.' });
            setSnackbarOpen(true);
        });
    };
    

    // Function to add a new file to the root or a folder
    const addFile = (parentId = 'root') => {
        const newItems = { ...items };
    
        const generateUniqueId = () => {
            // Generate a random 4-digit number
            return Math.floor(1000 + Math.random() * 9000).toString();
        };
    
        const getUniqueId = () => {
            let uniqueId = generateUniqueId();
            // Check if the generated ID already exists
            while (newItems[uniqueId]) {
                uniqueId = generateUniqueId();
            }
            return uniqueId;
        };
    
        const newFileId = getUniqueId(); // Get a unique 4-digit ID
    
        if (!newItems['root']) {
            newItems['root'] = {
                index: 'root',
                isFolder: true,
                type: 'folder',
                children: [],
                data: 'Root',
            };
        }

        newItems[parentId].children.push(newFileId);
        newItems[newFileId] = {
            index: newFileId,
            children: [],
            type: 'file',
            data: `New File ${newFileId}`,
        };
    
        fetch(Constants.APIS.CREATE_DOCUMENTATION_EDITOR_FILE, {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                email: props.userData.email,
                accessToken: props.userData.accessToken,
                fileIndex: newFileId,
                documentationProjectId: props.documentationProjectId,
                fileTreeViewjson: JSON.stringify(newItems),
                isFile:'Y'
            })
        })
        .then(response => response.json())
        .then(data => {
            if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                utils.deleteWebSession();
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
               
                setItems(newItems);
                props?.setFileTreeViewJson(newItems);
                // setIdCounter(idCounter + 1); // This might not be needed anymore
                setContextMenu(null);
            } else {
                setToastMsg({ type: 'error', msg: 'An error occurred while creating the File. Please try again.' });
                setSnackbarOpen(true);
            }
        })
        .catch(err => {
            setToastMsg({ type: 'error', msg: 'An error occurred while creating the File. Please try again.' });
            setSnackbarOpen(true);
        });
    };
    

    // Function to handle right-click on items
    const handleContextMenu = (event, item) => {

        event.preventDefault();

        const menuWidth = 150; // approximate width of your menu
        const menuHeight = 120; // approximate height of your menu

        // Calculate position to prevent going off-screen
        const mouseX = event.clientX + menuWidth > window.innerWidth
            ? window.innerWidth - menuWidth - 10
            : event.clientX - 2;

        const mouseY = event.clientY + menuHeight > window.innerHeight
            ? window.innerHeight - menuHeight - 10
            : event.clientY - 4;

        setContextMenu({
            mouseX,
            mouseY,
            itemId: item.index,
            isFolder: item.isFolder
        });

    };

    const handleDeleteFile1 = () => {
        if (contextMenu?.itemId) {
            const newItems = { ...items };
            const idsToRemove = []; // Array to hold all IDs to be removed

            // Recursive function to collect IDs
            const collectIds = (itemId) => {
                idsToRemove.push(itemId); // Add the current item ID

                // If the item has children, collect their IDs as well
                if (newItems[itemId]?.children) {
                    newItems[itemId].children.forEach(childId => collectIds(childId));
                }
            };

            // Start collecting IDs from the item being deleted
            collectIds(contextMenu.itemId);

            // Now delete the IDs from newItems
            idsToRemove.forEach(id => {
                delete newItems[id];
            });

            // Remove the IDs from their parent's children array
            Object.keys(newItems).forEach(key => {
                newItems[key].children = newItems[key].children.filter(child => !idsToRemove.includes(child));
            });

            setItems(newItems);
            props?.setFileTreeViewJson(newItems);
        }
    };

    const handleDeleteFile = async () => {
        if (contextMenu?.itemId) {
            let isApiCallSuccess = false;
            const newItems = JSON.parse(JSON.stringify(items)); // Create a deep clone
            const idsToRemove = []; // Array to hold all IDs to be removed
    
            // Helper function to collect IDs to remove
            const collectIds = (itemId) => {
                idsToRemove.push(itemId); // Add the current item ID
    
                // If the item has children, collect their IDs as well
                if (newItems[itemId]?.children) {
                    newItems[itemId].children.forEach(childId => collectIds(childId));
                }
            };
    
            // Start collecting IDs from the item being deleted
            collectIds(contextMenu.itemId);
    
            // Now delete the IDs from newItems
            idsToRemove.forEach(id => {
                delete newItems[id]; // Remove the item from the tree
            });
    
            // Remove the IDs from their parent's children array
            Object.keys(newItems).forEach(key => {
                if (newItems[key].children) {
                    newItems[key].children = newItems[key].children.filter(child => !idsToRemove.includes(child));
                }
            });
    
            // Special handling for the root folder
            if (newItems.root && newItems.root.children) {
                newItems.root.children = newItems.root.children.filter(child => !idsToRemove.includes(child));
            }
    
            // Perform the API call to delete files
            try {
                const response = await fetch(Constants.APIS.DELETE_DOCUMENTATION_EDITOR_FILE, {
                    method: "POST",
                    mode: 'cors',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    body: new URLSearchParams({
                        email: props.userData.email,
                        accessToken: props.userData.accessToken,
                        documentationProjectId: props.documentationProjectId,
                        fileIndex: JSON.stringify(idsToRemove), // IDs to remove
                        fileTreeViewjson: JSON.stringify(newItems) // Updated tree
                    })
                });
    
                const data = await response.json();
                if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                    utils.deleteWebSession();
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    isApiCallSuccess = true;
                } else {
                    throw new Error('API response was not successful');
                }
            } catch (error) {
                setToastMsg({ type: 'error', msg: 'An error occurred while deleting the File. Please try again.' });
                setSnackbarOpen(true);
            }
    
            // Only update the state if the API call was successful
            if (isApiCallSuccess) {
                let tmpItems = {...items}

                Object.keys(tmpItems).forEach(key => {
                    if (tmpItems[key].children) {
                        tmpItems[key].children = tmpItems[key].children.filter(child => !idsToRemove.includes(child));
                    }
                });
        
                // Special handling for the root folder
                if (tmpItems.root && tmpItems.root.children) {
                    tmpItems.root.children = tmpItems.root.children.filter(child => !idsToRemove.includes(child));
                }

                setItems(tmpItems);
                props?.setFileTreeViewJson(tmpItems);

                if( props.selectedFileItem?.index &&  idsToRemove.includes(props.selectedFileItem.index)){
                    props.handleSelectedItem(null)
                }
                setContextMenu(null)
            }
            setIsDeleteModalOpen(false)
        }
    };
    

    // Function to handle rename
    const openRenameModal = (itemId) => {
        setRenameItemId(itemId);
        setRenameValue(items[itemId].data); // Set the current name to the input field
        setShowModal(true);
        setContextMenu(null); // Close the context menu when opening the modal
    };

    const handleRename = () => {
        const parentItem = Object.values(items).find(item => item.children.includes(renameItemId));
        const siblingNames = parentItem ? parentItem.children.map(childId => items[childId].data) : [];

        if (siblingNames.includes(renameValue)) {
            setToastMsg({ type: 'error', msg: 'A file or folder with this name already exists in the parent folder.' });
            setSnackbarOpen(true)
            return;
        }

        const newItems = { ...items };
        newItems[renameItemId].data = renameValue; // Update the name
        setItems(newItems);
        props?.setFileTreeViewJson(newItems)
        setShowModal(false); // Close modal
        setRenameItemId(null); // Reset rename state
    };

    // Close the context menu
    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    return (
        <div className="w-64 bg-[#E5E5E5] shadow-lg p-4 overflow-y-auto h-screen relative" onClick={handleCloseContextMenu}>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-bold text-[#4B0082]">File Explorer</h2>
                <div className='flex'>
                    <RiFolderAddLine
                        className='mr-2 cursor-pointer'
                        size={20}
                        onClick={() => addFolder()}
                        title="Add Folder"
                    />
                    <AiOutlineFileAdd
                        className='cursor-pointer'
                        size={20}
                        onClick={() => addFile()}
                        title="Add File"
                    />
                </div>
            </div>

            {Object.keys(items).length > 0 ? (
                <UncontrolledTreeEnvironment
                    dataProvider={new StaticTreeDataProvider(items, (item, data) => ({ ...item, data }))}
                    getItemTitle={item => item.data}
                    viewState={{}}
                >
                    <Tree
                        treeId="tree-1"
                        rootItem="root"
                        treeLabel="Tree Example"
                        renderItemTitle={ele => (
                            <div
                                key ={ele.item.index}
                                className="flex items-center"
                                onContextMenu={(event) => handleContextMenu(event, ele.item)}
                                onClick={() => !ele.item.isFolder && props.handleSelectedItem(ele.item)}
                            >
                                {/* Only show the arrow provided by the tree, remove custom arrow */}
                                {ele.item.type === 'folder' ? (
                                    <RiFolderLine className="mr-2 text-blue-600" size={16} />
                                ) : (
                                    <AiOutlineFile className="mr-2 text-gray-600" size={16} />
                                )}
                                <span className={`${ele.item.index == props?.selectedFileItem?.index ? 'text-[#4B0082] font-bold' : 'text-gray-700'}`}>{ele.title}</span>
                            </div>
                        )}
                    />
                </UncontrolledTreeEnvironment>
            ) : (
                <div className="text-center text-gray-500">No items</div>
            )}

            {/* Context Menu */}
            {contextMenu && ReactDOM.createPortal(
                <div
                    className="fixed bg-white border border-gray-300 rounded shadow-md transition-transform transform scale-100 origin-top-left z-50"
                    style={{ top: contextMenu.mouseY, left: contextMenu.mouseX, minWidth: '150px' }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <ul className="p-2 space-y-1">
                        {contextMenu.isFolder && (
                            <>
                                <li
                                    className="cursor-pointer p-1 flex items-center hover:bg-gray-200 transition-colors"
                                    onClick={() => {
                                        addFile(contextMenu.itemId);
                                        handleCloseContextMenu(); // Close after clicking
                                    }}
                                >
                                    <RiFileAddLine className="mr-2 text-gray-600" size={16} />
                                    Add File
                                </li>
                                <li
                                    className="cursor-pointer p-1 flex items-center hover:bg-gray-200 transition-colors"
                                    onClick={() => {
                                        addFolder(contextMenu.itemId);
                                        handleCloseContextMenu(); // Close after clicking
                                    }}
                                >
                                    <RiFolder className="mr-2 text-gray-600" size={16} />
                                    Add Folder
                                </li>
                            </>
                        )}
                        <li
                            className="cursor-pointer p-1 flex items-center hover:bg-gray-200 transition-colors"
                            onClick={() => {
                                openRenameModal(contextMenu.itemId); // Open rename modal
                            }}
                        >
                            <RiEdit2Line className="mr-2 text-gray-600" size={16} />
                            Rename
                        </li>
                        <li
                            className="cursor-pointer p-1 flex items-center hover:bg-gray-200 transition-colors"
                            onClick={() => setIsDeleteModalOpen(true)}
                        >
                            <RiDeleteBin6Line className="mr-2 text-red-600" size={16} />
                            Delete
                        </li>
                    </ul>
                </div>,
                document.body // Render in body using Portal
            )}

            {/* Rename Modal */}
            <Modal open={showModal} onClose={() => setShowModal(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: '#f5f5f5',
                        boxShadow: 24,
                        p: 2,
                        borderRadius: 2,
                        outline: 'none',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <h2 style={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif', color: '#000', }}>
                            {renameItemId && items[renameItemId].isFolder ? 'Rename Folder' : 'Rename File'}
                        </h2>
                        <IconButton onClick={() => setShowModal(false)}>
                            <AiOutlineClose size={18} />
                        </IconButton>
                    </Box>

                    <div
                        style={{
                            backgroundColor: '#FFF',
                            boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.1)',
                            padding: '16px',
                            borderRadius: '10px',
                            marginBottom: '16px',
                        }}
                    >
                        <div style={{ marginBottom: '16px' }}>
                            <label htmlFor="name" style={{ display: 'block', fontWeight: 'bold', marginBottom: '8px', color: '#333' }}>
                                {renameItemId && items[renameItemId].isFolder ? 'Folder Name' : 'File Name'}
                            </label>
                            <input
                                type="text"
                                id="project-name"
                                name="name"
                                value={renameValue}
                                onChange={(e) => setRenameValue(e.target.value)}
                                style={{
                                    width: '100%',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                    fontSize: '16px',
                                    color: '#333',
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                        <button onClick={() => setShowModal(false)} style={{ marginRight: '16px', padding: '8px 16px', backgroundColor: 'transparent', border: '1px solid #ccc', borderRadius: '4px', cursor: 'pointer' }}>
                            Cancel
                        </button>
                        <button
                            onClick={handleRename}
                            className={`px-4 py-2 text-white border-none rounded cursor-pointer bg-[#4B0082]`}
                        >
                            Update
                        </button>
                    </div>
                </Box>
            </Modal>


            <ToastMessage
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={toastMsg}
            />

            <ConfirmationModal
                open={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                icon={RiDeleteBin6Fill}
                message="Are you sure you want to delete this project?"
                onConfirm={handleDeleteFile}
                confirmBtnText="Yes, I'm sure"
                cancelBtnText="No, cancel"
                confirmBtnColor="bg-red-600"
                cancelBtnColor="bg-transparent"
                confirmBtnTextColor="text-white"
                cancelBtnTextColor="text-gray-800"
            />
        </div>
    );
};

export default FileExpolerSideBar;
