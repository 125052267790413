import React, { useEffect, useState } from 'react';
import { AiOutlineClose, AiOutlineSearch, AiOutlineCalendar, AiOutlineEdit, AiOutlineFolderAdd } from 'react-icons/ai';
import { Modal, IconButton, Tooltip, Box, CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import utils from '../../common/utils';
import { Triangle } from 'react-loader-spinner'
import * as Constants from '../../common/constants'
import { FaInbox } from "react-icons/fa";
import ToastMessage from '../commonComponents/toastMessage'
import { RiDeleteBinLine } from "react-icons/ri";
import ConfirmationModal from '../commonComponents/conformationModal';
import { IoIosTrash } from "react-icons/io";
import { BiSolidEdit } from "react-icons/bi";


const DocumentationList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [projectName, setProjectName] = useState('')
    const [description, setDescription] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [pageNo, setPageNo] = useState(1)
    const [documentationList, setDocumentationList] = useState([])
    const [toastMsg, setToastMsg] = useState({ type: '', msg: '' });
    const [isCreateBtnLoading, setIsCreateBtnLoading] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isEditProject, setIsEditProject] = useState(false);
    const [selecteProjectId, setSelectedProjectId] = useState()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    const userData = utils.getUserSessionData();

    useEffect(() => {
        getDocumentProjects();
    }, [])


    const getDocumentProjects = () => {
        setIsLoading(true)
        fetch(Constants.APIS.GET_DOCUMENTATION_PROJECTS, {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                email: userData.email,
                accessToken: userData.accessToken,
                search: searchTerm,
                pageNo: pageNo
            })
        }).then(response => response.json())
            .then(data => {
                if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                    utils.deleteWebSession();
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    setDocumentationList(data.data)
                    setIsLoading(false)
                } else {
                    setToastMsg({ type: 'success', msg: data.responseMessage });
                    setIsLoading(false)
                }
            }).catch(err => {
                setToastMsg({ type: 'error', msg: 'An error occurred while creating the project. Please try again.' });
                setIsLoading(false)
            });
    }

    const handleActionClick = (event, project, action) => {
        if (action == 'edit') {
            setProjectName(project.projectName)
            setDescription(project.description)
            setSelectedProjectId(project.documentationProjectId)
            setIsEditProject(true)
            setOpen(true)
        } else if (action == 'delete') {
            setSelectedProjectId(project.documentationProjectId)
            setIsDeleteModalOpen(true)
        }
    };

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setProjectName('')
        setDescription('')
        setIsEditProject(false)
        setSelectedProjectId()
    };

    const handleDeleteProject = () => {
        setIsLoading(true);
        setIsDeleteModalOpen(false)
        fetch(Constants.APIS.DELETE_DOCUMENTATION_PROJECT, {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                email: userData.email,
                accessToken: userData.accessToken,
                documentationProjectId: selecteProjectId
            })
        }).then(response => response.json())
            .then(data => {
                if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                    utils.deleteWebSession();
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    setToastMsg({ type: 'success', msg: data.responseMessage });
                    setSnackbarOpen(true);
                    getDocumentProjects();
                    setSelectedProjectId()
                } else {
                    setToastMsg({ type: 'error', msg: data.responseMessage });
                    setSnackbarOpen(true);
                }
                setIsLoading(false);

            }).catch(err => {
                setToastMsg({ type: 'error', msg: 'An error occurred while deleting the project. Please try again.' });
                setSnackbarOpen(true);
                setIsLoading(false);
            });
    };

    const handleCreateNewProject = () => {
        let url = Constants.APIS.CREATE_DOCUMENTATION_PROJECT
        if (isEditProject) {
            url = Constants.APIS.EDIT_DOCUMENTATION_PROJECT
        }

        if (!projectName) {
            setToastMsg({ type: 'error', msg: 'Project name is required' });
            setSnackbarOpen(true)
        } else if (projectName.length >= 60) {
            setToastMsg({ type: 'error', msg: 'Project name must be less than 60 characters' });
            setSnackbarOpen(true)
        } else if (description && description.length >= 170) {
            setToastMsg({ type: 'error', msg: 'Project description must be less than 170 characters' });
            setSnackbarOpen(true)
        } else {
            setIsCreateBtnLoading(true)
            let params = {
                email: userData.email,
                accessToken: userData.accessToken,
                projectName: projectName,
                description: description
            }
            if (selecteProjectId && isEditProject) {
                params.documentationProjectId = selecteProjectId
            }
            fetch(url, {
                method: "POST",
                mode: 'cors',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams(params)
            }).then(response => response.json())
                .then(data => {
                    if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                        utils.deleteWebSession();
                    } else if (data.responseCode === Constants.CODE_SUCCESS) {
                        setOpen(false);
                        setProjectName('')
                        setDescription('')
                        setToastMsg({ type: 'success', msg: data.responseMessage })
                        setSnackbarOpen(true)
                        setSelectedProjectId()
                        getDocumentProjects()
                    } else {
                        setToastMsg({ type: 'error', msg: data.responseMessage });
                        setSnackbarOpen(true)
                    }
                    setIsCreateBtnLoading(false)
                })
                .catch(err => {
                    setToastMsg({ type: 'error', msg: 'An error occurred while creating the project. Please try again.' });
                    setSnackbarOpen(true)
                });
        }
    }


    return (
        <>
            {
                isLoading &&
                <div className="fixed inset-0 flex items-center justify-center">
                    <div className="flex flex-col items-center">
                        <Triangle
                            visible={true}
                            height="80"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="triangle-loading"
                        />
                        <p className="mt-4 text-lg font-bold text-gray-400 animate-pulse">Please wait, loading your projects...</p>
                    </div>
                </div>

            }
            {!isLoading &&
                <div className="flex flex-col">
                    {/* Fixed Header with Search Bar and Add Project Button */}
                    <div className="flex justify-between items-center mb-3">
                        {/* Search Bar on the Left */}
                        <div className="flex items-center bg-gray-200 rounded-full px-4 py-2 w-1/3 shadow-md transition duration-300 ease-in-out transform hover:scale-105">
                            <AiOutlineSearch className="text-gray-500 mr-2" size={20} />
                            <input
                                className="flex-grow bg-transparent outline-none text-gray-600"
                                placeholder="Search Projects"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        getDocumentProjects();
                                    }
                                }}
                            />
                        </div>

                        {/* Add Project Button on the Right */}
                        <Tooltip
                            title="Create New Project"
                            placement="top"
                            arrow
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: '#000',
                                        color: 'white',
                                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '8px',
                                        padding: '8px 12px',
                                        marginRight: '3px',
                                        fontSize: '12px'
                                    }
                                },
                                arrow: {
                                    sx: {
                                        color: '#000'
                                    }
                                }
                            }}
                        >
                            <div
                                className="flex items-center cursor-pointer text-[#4B0082] text-base font-bold hover:text-[#3EB489] transition duration-300"
                                onClick={handleOpenModal}
                            >
                                <div className="relative flex items-center justify-center ">
                                    <div className="w-10 h-10 bg-[#E5E5E5] rounded-full flex items-center justify-center">
                                        <div className="w-8 h-8 bg-white rounded-full flex items-center justify-center">
                                            <AiOutlineFolderAdd className="text-[#4B0082]" size={20} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tooltip>
                    </div>

                    {documentationList.length !== 0 &&
                        <div className="flex-1 overflow-y-auto" style={{ maxHeight: 'calc(82vh - 100px)', overflowY: 'auto' }}>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-2">
                                {documentationList.map((project) => (
                                    <motion.div
                                        key={project.documentationProjectId}
                                        className="relative p-4 cursor-pointer bg-white rounded-lg shadow-lg hover:shadow-2xl transition duration-300 group"
                                        whileHover={{ scale: 1.05 }}
                                        onClick={() => window.location.href = `${Constants.WEB_URLS.DOCUMENT_EDITOR}/${project.documentationProjectId}`}
                                    >
                                        <div className="flex justify-between items-center mb-2">
                                            <h2 className="text-xl font-semibold text-[#4B0082]">
                                                {project.projectName.length > 20 ? (
                                                    <span className="block">{project.projectName.slice(0, 20)}...</span>
                                                ) : (
                                                    project.projectName
                                                )}
                                            </h2>
                                            <div className="flex  opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                <IconButton onClick={(event) => { event.stopPropagation(); handleActionClick(event, project, 'edit') }}>
                                                    <BiSolidEdit className="text-[#000]" size={20} />
                                                </IconButton>
                                                <IconButton onClick={(event) => { event.stopPropagation(); handleActionClick(event, project, 'delete') }}>
                                                    <IoIosTrash className="text-red-500" size={20} />
                                                </IconButton>
                                            </div>
                                        </div>
                                        {project.description && (
                                            <p className="text-gray-600 text-sm">
                                                {project.description.length > 90 ? (
                                                    <span>{project.description.slice(0, 90)}...</span>
                                                ) : (
                                                    project.description
                                                )}
                                            </p>
                                        )}
                                        <div className="flex items-center text-sm text-gray-400 mt-2">
                                            <AiOutlineCalendar className="mr-1" size={16} />
                                            <span>Created: {new Date(project.createDate).toISOString().split('T')[0].replace(/-/g, '/')}</span>
                                        </div>
                                        {project.lastUpdateDate &&
                                            <div className="flex items-center text-sm text-gray-400">
                                                <AiOutlineEdit className="mr-1" size={16} />
                                                <span>Last Edited: {new Date(project.lastUpdateDate).toISOString().split('T')[0].replace(/-/g, '/')}</span>
                                            </div>
                                        }
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            }
            {!isLoading && documentationList.length === 0 &&
                <div className="relative flex items-center justify-center" style={{ height: 'calc(100vh - 300px)', zIndex: 1 }}>
                    <div className="flex flex-col items-center">
                        <FaInbox size={70} className="text-gray-400" style={{ animationIterationCount: 2 }} />
                        <p className="text-lg font-medium text-gray-400">No projects available</p>
                    </div>
                </div>
            }
            <Modal open={open} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: '#f5f5f5',
                        boxShadow: 24,
                        p: 2,
                        borderRadius: 2,
                        outline: 'none',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <h2 style={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif', color: '#000', }}>
                            {isEditProject ? 'Edit Project' : 'Create New Project'}
                        </h2>
                        <IconButton onClick={handleCloseModal}>
                            <AiOutlineClose size={18} />
                        </IconButton>
                    </Box>

                    <div
                        style={{
                            backgroundColor: '#FFF',
                            boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.1)',
                            padding: '16px',
                            borderRadius: '10px',
                            border: '1px solid #e0e0e0',
                            marginBottom: '16px',
                        }}
                    >
                        <div style={{ marginBottom: '16px' }}>
                            <label htmlFor="project-name" style={{ display: 'block', fontWeight: 'bold', marginBottom: '8px', color: '#333' }}>
                                Project Name
                            </label>
                            <input
                                type="text"
                                id="project-name"
                                name="name"
                                value={projectName}
                                onChange={(e) => setProjectName(e.target.value)}
                                style={{
                                    width: '100%',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                    fontSize: '16px',
                                    color: '#333',
                                }}
                            />
                        </div>
                        <div style={{ marginBottom: '16px' }}>
                            <label htmlFor="project-description" style={{ display: 'block', fontWeight: 'bold', marginBottom: '8px', color: '#333' }}>
                                Project Description
                            </label>
                            <textarea
                                id="project-description"
                                name="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                rows="4"
                                style={{
                                    width: '100%',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                    fontSize: '16px',
                                    color: '#333',
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                        <button onClick={handleCloseModal} style={{ marginRight: '16px', padding: '8px 16px', backgroundColor: 'transparent', border: '1px solid #ccc', borderRadius: '4px', cursor: 'pointer' }}>
                            Cancel
                        </button>
                        <button
                            onClick={handleCreateNewProject}
                            className={`px-4 py-2 text-white border-none rounded cursor-pointer ${isCreateBtnLoading ? 'bg-gray-400' : 'bg-[#4B0082]'}`}
                            disabled={isCreateBtnLoading}
                        >
                            {isCreateBtnLoading ? (
                                <div className="flex items-center">
                                    <CircularProgress size={20} className="mr-2" />
                                    Loading...
                                </div>
                            ) : (
                                isEditProject ? 'Update' : 'Create'
                            )}
                        </button>
                    </div>
                </Box>
            </Modal>


            <ToastMessage
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={toastMsg}
            />
            <ConfirmationModal
                open={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                icon={RiDeleteBinLine}
                message="Are you sure you want to delete this Project ?"
                onConfirm={handleDeleteProject}
                confirmBtnText="Yes, I'm sure"
                cancelBtnText="No, cancel"
                confirmBtnColor="bg-red-600"
                cancelBtnColor="bg-transparent"
                confirmBtnTextColor="text-white"
                cancelBtnTextColor="text-gray-800"
            />
        </>
    );
};

export default DocumentationList;
