import React, { useState, useEffect } from 'react';
import { CircularProgress, Typography, Snackbar, SnackbarContent } from '@mui/material'; // Import SnackbarContent
import { useParams } from 'react-router-dom';
import * as Constants from '../../common/constants';

const EmailVerificationScreen = () => {
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [message, setMessage] = useState(''); // State for message
    const [open, setOpen] = useState(false); // State for Snackbar visibility
    const [isValidVerificationId,setIsValidVerificationId] = useState(true);

    let params = useParams();
   
    useEffect(() => {
        if(params.id && params.id.length !== 25){
            setIsValidVerificationId(false);
            window.location.href = Constants.WEB_URLS.LOGIN;
        }else{
            emailVerification();
        }
    }, []);

    const emailVerification = () => {
        fetch(Constants.APIS.EMAIL_VERIFICATION, {
            method: "POST",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                verificationId: params.id
            })
        }).then(response => response.json())
            .then(data => {
                if (data.responseCode === Constants.CODE_SUCCESS) {
                    setMessage(data.responseMessage ); // Success message
                    setIsEmailVerified(true);
                } else {
                    setMessage(data.responseMessage ); // Error message
                }
                setOpen(true); // Show Snackbar
                setTimeout(() => {
                    window.location.href= '/'// Redirect to home page after 4 seconds
                }, 4000);
            })
            .catch(err => {
                setMessage('An error occurred.'); // Error message
                setOpen(true); // Show Snackbar
                setTimeout(() => {
                    window.location.href= '/'; // Redirect to home page after 4 seconds
                }, 4000);
            });

    }
    const handleClose = () => {
        setOpen(false); // Close Snackbar
    };

    return ( isValidVerificationId &&
        <div className="min-h-screen flex flex-col justify-center items-center bg-[#E5E5E5] p-4 font-sans animate-fadeIn">
            <Typography variant="h6" component="p" className="mb-4 animate-pulse">
                We are verifying your email, please wait a while...
            </Typography>
            <CircularProgress />
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position at top center
            >
                <SnackbarContent
                    style={{
                        backgroundColor: isEmailVerified ? 'green' : 'red', // Green for success, red for error
                        color: 'white',
                        fontWeight: 'bold',
                    }}
                    message={message}
                />
            </Snackbar>
        </div>
    );
};

export default EmailVerificationScreen;