// src/pages/userAuth/forgotPassword.js
import React, { useState } from 'react';
import { Button, Snackbar, SnackbarContent } from '@mui/material'; // Import Snackbar components
import { FiKey, FiArrowLeft } from 'react-icons/fi'; // Import close icon
import { motion } from 'framer-motion'; // For animations
import * as Constants from '../../common/constants';
import { MdOutlineReportGmailerrorred } from "react-icons/md";
import { IoCheckmarkDoneCircle } from "react-icons/io5";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState({});
    const [isResetPasswordClick,setIsResetPasswordClick] = useState(false);

    const handleResetPasswordClick = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
            setSnackbarMessage({ type:'error', msg:"Email is required."});
            setSnackbarOpen(true); 
        }else if (!emailRegex.test(email)) {
            setSnackbarMessage({ type:'error', msg:"Invalid Email address."});
            setSnackbarOpen(true); 
        }else{
            setIsResetPasswordClick(true);

            fetch(Constants.APIS.FORGOT_PASSWORD, {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    email: email,
                })

            }).then(response => response.json()) .then(data => {
                setIsResetPasswordClick(false)
                if (data.responseCode === Constants.CODE_SUCCESS) {
                    setEmail("")
                    setSnackbarMessage({ type:'success', msg:data.responseMessage});
                    setSnackbarOpen(true); 
                    setTimeout(() => {
                        window.location.href= Constants.WEB_URLS.LOGIN
                    }, 4000);

                } else {
                    setSnackbarMessage({ type:'error', msg:data.responseMessage});
                    setSnackbarOpen(true); 
                }
            })
            .catch(err => {
                setIsResetPasswordClick(false)
                setSnackbarMessage({ type:'error', msg:"An error occurred during login. Please try again."});
                setSnackbarOpen(true); 
            });


        }
    };


    return (
        <>
            <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className="min-h-screen flex flex-col justify-start items-center bg-white p-4 font-sans"
            >
                {/* Icon inside double circle */}
                <div className="relative mb-6 mt-20"> {/* Margin from the top */}
                    <div className="flex justify-center items-center w-24 h-24 rounded-full bg-[#E5E5E5]">
                        <div className="flex justify-center items-center w-16 h-16 rounded-full bg-[#F5F5F5]">
                            <FiKey size={32} className="text-[#4B0082]" />
                        </div>
                    </div>
                </div>

                <h2 className="text-2xl font-semibold text-center text-gray-900 mb-2">
                    Forgot password?
                </h2>

                <p className="text-center text-gray-500 mb-6">
                    No worries, we'll send you reset instructions.
                </p>

                <div className="w-full max-w-xs mb-4">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#4B0082]"
                    />
                </div>

                <Button
                    variant="contained"
                    fullWidth
                    style={{
                        backgroundColor: isResetPasswordClick ? '#ccc' : '#4B0082', // Change color when loading
                        color: 'white'
                    }}
                    onClick={handleResetPasswordClick}
                    className="max-w-xs mb-4"
                    disabled={isResetPasswordClick} // Disable button when loading
                >
                    {isResetPasswordClick ? 'Loading...' : 'Reset password'} {/* Show loading text */}
                </Button>

                <p className="text-center mt-4 text-gray-500 cursor-pointer hover:underline flex items-center justify-center"
                    onClick={() => window.history.back(-2)}
                >
                    <FiArrowLeft className="mr-2" /> 
                    Back to log in
                </p>
            </motion.div>

            {/* Snackbar for messages */}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={() => setSnackbarOpen(false)}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: 'white', // Light red background
                        color: 'black', // Dark red text
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    message={
                        <div style={{ display: 'flex', alignItems: 'center', lineHeight: '1.5' }}>
                           {snackbarMessage?.type=="error" ? 
                           <MdOutlineReportGmailerrorred style={{ marginRight: '5px', fontSize: '18px', color: 'red' }} />:
                           <IoCheckmarkDoneCircle style={{ marginRight: '5px', fontSize: '18px', color: 'green' }} />
                           }
                            <span>{snackbarMessage?.msg}</span>
                        </div>
                    }
                   
                />
            </Snackbar>
        </>
    );
};

export default ForgotPassword;