import React,{useState} from 'react';
import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { IoHammerSharp, IoWalletOutline } from "react-icons/io5";
import { GiWallet } from "react-icons/gi";
import ToastMessage from '../commonComponents/toastMessage'

// Sidebar component
const RightSidebar = ({ handleCreateDocusurasProject, userData, userCredits }) => {
    const menuItems = [
        {
            name: `Available Credits: ${userCredits || 0}`,
            icon: (
                <Tooltip
                    {...{
                        placement: "right",
                        arrow: true,
                        TransitionComponent: Zoom,
                        componentsProps: {
                            tooltip: {
                                sx: {
                                    backgroundColor: '#000',
                                    color: 'white',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '8px',
                                    fontSize: '14px',
                                    padding: '8px 12px',
                                }
                            },
                            arrow: {
                                sx: {
                                    color: '#000'
                                }
                            }
                        }
                    }}
                >
                    <div className="flex items-center justify-center cursor-pointer">
                        <GiWallet size={26} color="#B8860B" /> {/* Wallet icon */}
                    </div>
                </Tooltip>
            ),
            id: 'credits'
        },
        {
            name: 'Build',
            icon: (
                <div style={{ position: 'relative', width: 30, height: 30 }}>
                    {/* Metal Head */}
                    <IoHammerSharp
                        size={30}
                        color="#4F4F4F" // Dark gray for the hammer head
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            clipPath: 'inset(0 0 50% 0)' // Clipping to show only top part
                        }}
                    />
                    {/* Wooden Handle */}
                    <IoHammerSharp
                        size={30}
                        color="#A0522D" // Wood color for the handle
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            clipPath: 'inset(50% 0 0 0)' // Clipping to show only bottom part
                        }}
                    />
                </div>
            ),
            id: 'build'
        },
    ];

    const handleMenuSelected = (index) =>{
        console.log(index)
        if(index=='build'){
            handleCreateDocusurasProject();
        }
    }

    return (
        <div className="w-12 bg-[#E5E5E5] flex flex-col items-center py-4 space-y-4 shadow-lg pt-20">
            {menuItems.map((item, index) => (
                <Tooltip
                    key={item.id}
                    title={<span className="text-sm font-medium text-white">{item.name}</span>}
                    placement="right"
                    arrow
                    TransitionComponent={Zoom}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: '#000',
                                color: 'white',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8px',
                                fontSize: '14px',
                                padding: '8px 12px',
                            }
                        },
                        arrow: {
                            sx: {
                                color: '#000'
                            }
                        }
                    }}
                >
                    <div
                        onClick={() => handleMenuSelected(item.id)}
                        className="relative flex items-center justify-center cursor-pointer"
                    >
                        {item.icon}
                    </div>
                </Tooltip>
            ))}
        </div>
    );
};

export default RightSidebar;
