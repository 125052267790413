import React, { useState, useEffect } from 'react';
import { Button} from '@mui/material'; // Import Snackbar components
import { motion } from 'framer-motion'; // For animations
import * as Constants from '../../common/constants';
import { MdOutlineReportGmailerrorred } from "react-icons/md";
import { IoCheckmarkDoneCircle, IoLockClosed } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import ToastMessage from '../commonComponents/toastMessage';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState(''); // New state for password
    const [confirmPassword, setConfirmPassword] = useState(''); // New state for confirm password
    const [passwordStarted, setPasswordStarted] = useState(false); // Track if password input has started
    const [isResetPasswordClick, setIsResetPasswordClick] = useState(false);
    const [isValidResetId, setIsValidResetId] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState({});

    let params = useParams();

    useEffect(() => {
        if (params.id && params.id.length != 25) {
            setIsValidResetId(false);
            window.location.href = Constants.WEB_URLS.LOGIN
        }
    }, []);


    const passwordCriteria = [
        {
            label: "At least 8 characters",
            isValid: newPassword.length >= 8,
        },
        {
            label: "Contains an uppercase letter (A-Z)",
            isValid: /[A-Z]/.test(newPassword),
        },
        {
            label: "Contains a lowercase letter (a-z)",
            isValid: /[a-z]/.test(newPassword),
        },
        {
            label: "Contains a number (0-9)",
            isValid: /\d/.test(newPassword),
        },
        {
            label: "Contains a special character (!@#$%^&*)",
            isValid: /[!@#$%^&*]/.test(newPassword),
        },
    ];

    const isPasswordValid = passwordCriteria.every((criterion) => criterion.isValid);

    const handleResetPasswordClick = () => {
        if (!newPassword) {
            setSnackbarMessage({ type: 'error', msg: "Password is required." });
            setSnackbarOpen(true);
        } else if (!isPasswordValid) {
            setSnackbarMessage({ type: 'error', msg: "Password does not meet the required criteria." });
            setSnackbarOpen(true);
        } else if (!confirmPassword) {
            setSnackbarMessage({ type: 'error', msg: "Confirm password is required." });
            setSnackbarOpen(true);
        } else if (newPassword !== confirmPassword) {
            setSnackbarMessage({ type: 'error', msg: "Confirm password must match the password." });
            setSnackbarOpen(true);
        } else {
            setIsResetPasswordClick(true)
            fetch(Constants.APIS.RESET_PASSWORD, {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    resetId: params.id,
                    newPassword: newPassword
                })
            }).then(response => response.json()).then(data => {
                setIsResetPasswordClick(false)
                if (data.responseCode === Constants.CODE_SUCCESS) {
                    setSnackbarMessage({ type: 'success', msg: data.responseMessage });
                    setNewPassword('')
                    setConfirmPassword('')
                    setPasswordStarted(false)
                    setSnackbarOpen(true);
                    setTimeout(() => {
                        window.location.href = Constants.WEB_URLS.LOGIN
                    }, 4000);
                } else {
                    setSnackbarMessage({ type: 'error', msg: data.responseMessage });
                    setSnackbarOpen(true);
                }
            }).catch(err => {
                console.log(err)
                setIsResetPasswordClick(false)
                setSnackbarMessage({ type: 'error', msg: "An error occurred during login. Please try again." });
                setSnackbarOpen(true);
            });

        }
    };

    return (isValidResetId &&
        <>
            <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className="min-h-screen flex flex-col justify-start items-center bg-white p-4 font-sans"
            >
                {/* Icon inside double circle */}
                <div className="relative mb-6 mt-20"> {/* Margin from the top */}
                    <div className="flex justify-center items-center w-24 h-24 rounded-full bg-[#E5E5E5]">
                        <div className="flex justify-center items-center w-16 h-16 rounded-full bg-[#F5F5F5]">
                            <IoLockClosed size={32} className="text-[#4B0082]" />
                        </div>
                    </div>
                </div>

                <h2 className="text-2xl font-semibold text-center text-gray-900 mb-2">
                    Create new password
                </h2>

                <p className="text-center text-gray-500 mb-6">
                    Enter your new password below to <br /> complete the reset process.
                </p>

                <div className="w-full max-w-xs mb-4">
                    <input
                        type="password"
                        placeholder="New Password"
                        value={newPassword} // Update state
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                            setPasswordStarted(true); // Set password started on input change
                        }} // Update state
                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#4B0082]"
                    />
                </div>
                <div className="w-full max-w-xs mb-4">
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword} // Update state
                        onChange={(e) => setConfirmPassword(e.target.value)} // Update state
                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#4B0082]"
                    />
                </div>
                <Button
                    variant="contained"
                    fullWidth
                    style={{
                        backgroundColor: isResetPasswordClick ? '#ccc' : '#4B0082', // Change color when loading
                        color: 'white'
                    }}
                    onClick={handleResetPasswordClick}
                    className="max-w-xs mb-4"
                    disabled={isResetPasswordClick} // Disable button when loading
                >
                    {isResetPasswordClick ? 'Loading...' : 'Reset password'}
                </Button>

                <div className="mb-4">
                    {passwordStarted && !isPasswordValid && (
                        <div className="w-full p-4 mb-6">
                            <hr className="mb-3" />
                            <p className="text-gray-700 mb-4">
                                Your password must contain at least:
                            </p>
                            {passwordCriteria.map((criterion, index) => (
                                <div key={index} className="flex items-center mb-2">
                                    {criterion.isValid ? (
                                        <IoCheckmarkDoneCircle className="text-green-600 mr-2" />
                                    ) : (
                                        <MdOutlineReportGmailerrorred className="text-red-600 mr-2" />
                                    )}
                                    <p
                                        className={`text-sm ${criterion.isValid ? "text-green-600" : "text-red-600"}`}
                                    >
                                        {criterion.label}
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <ToastMessage
                  open={snackbarOpen}
                  onClose={() => setSnackbarOpen(false)}
                  message={snackbarMessage}
                />
            </motion.div>
        </>
    );
};

export default ResetPassword;