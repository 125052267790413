import React from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';
import { MdOutlineReportGmailerrorred } from 'react-icons/md';
import { IoCheckmarkDoneCircle } from 'react-icons/io5';

const ToastMessage = ({ open, onClose, message }) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={5000}
            onClose={onClose}
        >
            <SnackbarContent
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                }}
                message={
                    <div style={{ display: 'flex', alignItems: 'center', lineHeight: '1.5' }}>
                        {message?.type === "error" ? (
                            <MdOutlineReportGmailerrorred style={{ marginRight: '5px', fontSize: '18px', color: 'red' }} />
                        ) : (
                            <IoCheckmarkDoneCircle style={{ marginRight: '5px', fontSize: '18px', color: 'green' }} />
                        )}
                        <span>{message?.msg}</span>
                    </div>
                }
            />
        </Snackbar>
    );
};

export default ToastMessage;