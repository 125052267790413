import React from 'react';
import { Tooltip } from '@mui/material';
import { FaShapes } from 'react-icons/fa';
import { FaFolderTree } from "react-icons/fa6";
import Zoom from '@mui/material/Zoom';

// Sidebar component
const Sidebar = ({ selectedMenu, setSelectedMenu, userData }) => {
    const menuItems = [
        { name: 'File Explorer', icon: <FaFolderTree />, id: '1' },
        // { name: 'Elements', icon: <FaShapes />, id: '2' },
    ];

    const tooltipProps = {
        placement: "right",
        arrow: true,
        TransitionComponent: Zoom,
        componentsProps: {
            tooltip: {
                sx: {
                    backgroundColor: '#000',
                    color: 'white',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    fontSize: '14px',
                    padding: '8px 12px',
                }
            },
            arrow: {
                sx: {
                    color: '#000'
                }
            }
        }
    };

    return (
        <div className="w-16 bg-[#E5E5E5] flex flex-col items-center py-4 space-y-4 shadow-lg">
            {/* SaaS Name */}
            <div className="items-center justify-center h-8 text-[#4B0082] font-bold text-lg mb-2">
                NW
            </div>
            {menuItems.map((item, index) => (
                <Tooltip

                    title={<span className="text-sm font-medium text-white">{item.name}</span>}
                    {...tooltipProps}
                >
                    <div
                        onClick={() => setSelectedMenu(item.id)}
                        className={`text-[#4B0082] p-3 cursor-pointer rounded-[10px] flex items-center justify-center ${selectedMenu === item.id ? 'bg-[#4B0082] text-white' : ''}`}
                    >
                        {item.icon}
                    </div>
                </Tooltip>
            ))}
            <div className="flex-grow"></div>
            {/* User Avatar */}
            <Tooltip
                title={<span className="text-sm font-medium text-white">{userData?.firstName?.charAt(0).toUpperCase() + userData?.firstName?.slice(1)} {userData?.lastName?.charAt(0).toUpperCase() + userData?.lastName?.slice(1)}</span>}
                {...tooltipProps}
            >
                <div className="w-10 h-10 bg-[#4B0082] text-white flex items-center justify-center rounded-full cursor-pointer mb-4">
                    <span className="text-lg font-bold">{userData?.firstName?.charAt(0).toUpperCase()}</span>
                </div>
            </Tooltip>
        </div>
    );
};

export default Sidebar;
