import React, { useEffect, useState, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { Tooltip, Breadcrumbs, Link, Typography } from '@mui/material';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Sidebar from './sidebar';
import RightSidebar from './rightSidebar';
import ElementSideBar from './elementSidebar';
import FileExpolerSideBar from './fileExplorer';
import { Triangle,FallingLines } from 'react-loader-spinner';
import NotionEditor from './notionEditor';
import utils from '../../common/utils';
import * as Constants from '../../common/constants';
import { useParams } from 'react-router-dom';
import ToastMessage from '../commonComponents/toastMessage'


const DocumentationEditor = () => {
    const [selectedMenu, setSelectedMenu] = useState('1');
    const [selectedFileItem, setSelectedFileItem] = useState(null);
    const [projectDetials, setProjectDetials] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedEditorContent, setSelectedEditorContent] = useState('');
    const [fileTreeViewjson, setFileTreeViewJson] = useState({});
    const [allFileEditorData, setAllFileEditorData] = useState([])
    const [toastMsg, setToastMsg] = useState({ type: '', msg: '' });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isBuildProject, setIsBuildProject] = useState(false);

    const userData = utils.getUserSessionData();
    let params = useParams();
    const typingTimeoutRef = useRef(null);

    useEffect(() => {
        getProjectDetials();
    }, []);

    const handleCreateDocusurasProject = () => {
        setIsBuildProject(true)
        fetch(Constants.APIS.BUILD_DOCUSURAS_PROJECT, {
            method: "POST",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                email: userData.email,
                accessToken: userData.accessToken,
                documentationProjectId: parseInt(params?.id),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
               
                if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                    utils.deleteWebSession();
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    console.log(data)
                    const link = document.createElement('a');
                    link.href = data.data;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }else{
                    setToastMsg({ type: 'error', msg: 'An error occurred while deleting the project. Please try again.' });
                    setSnackbarOpen(true);
                }
                setIsBuildProject(false)
            })
    }

    const getProjectDetials = () => {
        setIsLoading(true);
        fetch(Constants.APIS.GET_DOCUMENT_PROJECT_DETIALS, {
            method: "POST",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                email: userData.email,
                accessToken: userData.accessToken,
                documentationProjectId: parseInt(params?.id),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                    utils.deleteWebSession();
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    setProjectDetials(data.documentationProjectData);
                    if (data.documentationProjectData?.treeViewJson) {
                        setFileTreeViewJson(JSON.parse(data.documentationProjectData?.treeViewJson))
                    }
                    setAllFileEditorData(data.allFileEditorData)
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const handleContentChange = (content) => {
        if (selectedFileItem?.index) {
            setSelectedEditorContent(content);
            let tmpFileEditorData = allFileEditorData.map((item) => {
                if (selectedFileItem.index == item.fileIndex) {
                    item.content = content
                }
                return item
            })
            setAllFileEditorData(tmpFileEditorData)

            if (typingTimeoutRef.current) {
                clearTimeout(typingTimeoutRef.current);
            }

            typingTimeoutRef.current = setTimeout(() => {
                saveFileEditorData()
                // Call the API after 5 seconds of inactivity
                // callApi(content);
            }, 2000);
        }
    };

    const saveFileEditorData = () => {

        fetch(Constants.APIS.SAVE_FILE_CONTENT, {
            method: "POST",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                email: userData.email,
                accessToken: userData.accessToken,
                documentationProjectId: parseInt(params?.id),
                fileIndex: selectedFileItem.index,
                content: selectedEditorContent
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                    utils.deleteWebSession();
                }
            })
    }


    const handleFileSelectChange = (item) => {
        if (item?.index && fileTreeViewjson[item.index]) {
            setSelectedFileItem(item);
            let tmpFileEditorData = allFileEditorData.find((ele) => ele.fileIndex == item.index)
            if (tmpFileEditorData) {
                setSelectedEditorContent(tmpFileEditorData.content)
            } else {
                setSelectedEditorContent('')
            }
        } else {
            setSelectedEditorContent(''); // Set to an empty string if no editorData
        }
    };

    return (
        <>
            {isLoading &&
                <div className="fixed inset-0 flex items-center justify-center">
                    <div className="flex flex-col items-center">
                        <Triangle visible={true} height="80" width="80" color="#4fa94d" />
                        <p className="mt-4 text-l font-bold text-gray-400 animate-pulse">Please wait, loading project data...</p>
                    </div>
                </div>
            }  
            {isBuildProject &&
                <div className="fixed inset-0 flex items-center justify-center">
                    <div className="flex flex-col items-center">
                        <FallingLines visible={true} height="80" width="80" color="#4fa94d" />
                        <p className="mt-4 text-l font-bold text-gray-500 animate-pulse">Please hold on as we prepare your Docusaurus project. This may take a few moments.</p>
                    </div>
                </div>
            }  
            {!isLoading && !isBuildProject &&
                <DndProvider backend={HTML5Backend}>
                    <div className="h-screen flex overflow-hidden">
                        <Sidebar selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} userData={userData} />

                        {selectedMenu === "1" && (
                            <FileExpolerSideBar
                                handleSelectedItem={handleFileSelectChange}
                                selectedFileItem={selectedFileItem}
                                setFileTreeViewJson={setFileTreeViewJson}
                                fileTreeViewjson={fileTreeViewjson}
                                userData={userData}
                                documentationProjectId={parseInt(params.id)}
                                allFileEditorData={allFileEditorData}
                                setAllFileEditorData={setAllFileEditorData}
                            />
                        )}
                        {selectedMenu === "2" && <ElementSideBar />}
                        <div className="flex-grow flex flex-col bg-gray-100">
                            <div className="w-full bg-[#F5F5F5] h-[40px] flex items-center justify-between px-6">
                                {selectedFileItem?.data &&
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="none" color="inherit" sx={{ color: '#000', fontSize: '14px' }}>
                                            {projectDetials?.projectName}
                                        </Link>
                                        <Typography sx={{ color: '#4B0082', fontSize: '14px', fontWeight: 'bold' }}>{selectedFileItem?.data}</Typography>
                                    </Breadcrumbs>
                                }
                            </div>

                            <div className="flex-grow flex justify-center mt-2 p-2 overflow-auto">
                                {selectedFileItem?.index && selectedFileItem?.type === "file" && (
                                    <NotionEditor
                                        handleContentChange={handleContentChange}
                                        selectedEditorContent={selectedEditorContent}
                                        index={selectedFileItem.index}
                                    />
                                )}
                            </div>
                        </div>

                        <RightSidebar handleCreateDocusurasProject={handleCreateDocusurasProject} userData={userData} />
                    </div>
                    <ToastMessage
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={toastMsg}
            />
                </DndProvider>
            }
        </>
    );
};

export default DocumentationEditor;
