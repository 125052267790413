import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { CircularProgress } from '@mui/material';
import * as Constants from '../../common/constants'

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [isLoginClick, setIsLoginClick] = useState(false);
    const [error, setError] = useState();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        const userData = localStorage.getItem('userData');
        if (userData) {
            window.location.href = Constants.WEB_URLS.DASHBOARD;
        } else {
            localStorage.removeItem('userData');
        }
    }, []);

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => setError(), !error?.serverError ? 4000 : 8000); // Clear error after 5 seconds
            return () => clearTimeout(timer);
        }
    }, [error]);

    const handleLoginClick = () => {
        let newError = {}
        let isError = false;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!email) {
            newError.email = 'Email is required';
            isError = true
        } else if (!emailRegex.test(email)) {
            newError.email = 'Invalid Email address';
            isError = true
        } else if (!password) {
            newError.password = 'Password is required';
            isError = true
        }
        if (isError) {
            setError(newError);
        } else {
            setIsLoginClick(true)
            fetch(Constants.APIS.USER_LOGIN, {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    email: email,
                    password: password
                })

            }).then(response => response.json()).then(data => {
                setIsLoginClick(false)
                if (data.responseCode === Constants.CODE_SUCCESS) {
                    setEmail("")
                    setPassword("")
                    localStorage.setItem('userData', JSON.stringify(data.data));
                    window.location.href = Constants.WEB_URLS.DASHBOARD
                } else {
                    setError({ serverError: data.responseMessage });
                }
            })
                .catch(err => {
                    setIsLoginClick(false)
                    setError({ serverError: "An error occurred during login. Please try again." });
                });
        }
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-[#E5E5E5] p-4 font-sans">
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row items-center">
                    {/* Left column */}
                    <div className="md:w-1/2 text-center md:text-left flex flex-col justify-center mb-10 md:mb-0 p-8 text-gray-800 
                        animate-slideInLeft">
                        <h1 className="text-4xl font-bold leading-tight mb-5 animate-pulseSlow">
                            Welcome to <br />
                            <span className="text-[#4B0082]">Neverwrite</span>
                        </h1>
                        <p className="text-gray-700 text-lg mb-4">
                            Create product documentation instantly from your video recordings with the power of AI.
                        </p>
                        <p className="text-gray-700 text-lg">
                            Streamline your workflow, save time, and focus on what matters.
                            Neverwrite takes care of the documentation fast, easy, and tailored for your business.
                        </p>
                    </div>

                    {/* Right column - Login form */}
                    <div className="w-[40%] m-5 bg-white rounded-lg shadow-lg p-10 animate-fadeIn" onKeyPress={(e) => e.key === 'Enter' && handleLoginClick()}>
                        <h2 className="text-3xl font-bold text-center mb-6">Login</h2>
                        <p className="text-center text-gray-600 mb-6">Welcome back! Please login to your account.</p>

                        {error?.serverError && (
                            <p className="text-red-500  mb-3 text-center animate-bounce transition-opacity duration-500 opacity-100">
                                {error.serverError}
                            </p>
                        )}

                        {error?.email && (
                            <p className="text-red-500 animate-bounce transition-opacity duration-500 opacity-100">
                                {error.email}
                            </p>
                        )}
                        <input
                            type="email"
                            value={email}
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#4B0082]"
                        />

                        {error?.password && (
                            <p className="text-red-500 animate-bounce transition-opacity duration-500 opacity-100">
                                {error.password}
                            </p>
                        )}
                        <div className="relative w-full mb-4">
                            <input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#4B0082]"
                            />
                            <div
                                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600 cursor-pointer"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </div>
                        </div>


                        <div className="flex justify-between items-center mb-6">
                            <div></div>
                            <a onClick={() => { window.location.href = Constants.WEB_URLS.FORGOT_PASSWORD }} className="text-[#4B0082] hover:underline text-sm cursor-pointer">Forgot Password?</a>
                        </div>

                        <button
                            className={`w-[50%] mx-auto block bg-[#3EB489] text-white py-2 rounded  ${isLoginClick ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-[#32a078] transition duration-300'}`} // Change color when disabled
                            onClick={handleLoginClick}
                            disabled={isLoginClick} // Disable button when loading
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} // Flexbox for centering content
                        >
                            {isLoginClick && (
                                <CircularProgress
                                    size={20} // Adjust size as needed
                                    color="inherit"
                                    style={{ marginRight: 8 }} // Space between spinner and text
                                />
                            )}
                            {isLoginClick ? 'Log In...' : 'Log In'} {/* Change text based on loading state */}
                        </button>

                        <div className="text-center mt-6">
                            <p className="text-gray-600">Don't have an account? <a onClick={() => { window.location.href = Constants.WEB_URLS.SIGNUP }} className="text-[#4B0082] cursor-pointer">Sign up</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
