import React from 'react';
import { Modal, Box } from '@mui/material';

const ConfirmationModal = ({ 
    open, 
    onClose, 
    icon: Icon, 
    message, 
    onConfirm, 
    confirmBtnText = "Yes, I'm sure", 
    cancelBtnText = "No, cancel", 
    confirmBtnColor = "bg-red-600", 
    cancelBtnColor = "bg-transparent", 
    confirmBtnTextColor = "text-white", 
    cancelBtnTextColor = "text-gray-800" ,
    description = "Deleting will permanently remove this from the system. This cannot be undone!", // Description text from props
   
}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="confirmation-modal"
            aria-describedby="confirmation-description"
        >
            <Box 
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[550px] bg-white p-6 rounded-lg text-center"
                style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)' }}
            >
                {/* Icon with Circular Background */}
                <div className="mb-4 flex justify-center">
                    <div className="bg-red-100 rounded-full p-4">
                        <Icon size={22} className="text-red-500" />
                    </div>
                </div>

                {/* Confirmation Text */}
                <h2 id="confirmation-modal" className="text-xl font-semibold text-gray-800 mb-2">
                    {message}
                </h2>
                
                {/* Description Text */}
                <p id="confirmation-description" className="text-gray-600 mb-6">
                    {description}
                </p>

                {/* Action Buttons */}
                <div className="flex justify-center gap-4">
                    {/* Cancel Button */}
                    <button
                        onClick={onClose}
                        className={`px-6 py-2 ${cancelBtnColor} font-semibold rounded-lg cursor-pointer hover:bg-gray-200 transition duration-200 ${cancelBtnTextColor}`}
                    >
                        {cancelBtnText}
                    </button>

                    {/* Confirm/Delete Button */}
                    <button
                        onClick={onConfirm}
                        className={`px-6 py-2 ${confirmBtnColor} font-semibold rounded-lg cursor-pointer hover:bg-red-100 transition duration-200 ${confirmBtnTextColor}`}
                    >
                        {confirmBtnText}
                    </button>
                </div>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;
