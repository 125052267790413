import React from 'react';
import { 
    FaFileUpload, 
    FaPalette, 
    FaFont, 
    FaProjectDiagram, 
} from 'react-icons/fa';
import { useDrag } from 'react-dnd';

const ElementSideBar = (props) => {
    // Define the draggable elements
    const draggableElements = [
        { name: 'Text Area', type: 'text', icon: <FaFont /> },
        // { name: 'Image', type: 'image', icon: <FaFileUpload /> },
        // { name: 'Info Card', type: 'infoCard', icon: <FaPalette /> },
        // { name: 'Video', type: 'video', icon: <FaProjectDiagram /> },
    ];

    // Draggable Element Component
    const DraggableElement = ({ element }) => {
        const [{ isDragging }, drag] = useDrag(() => ({
            type: props.ItemTypes.ELEMENT,
            item: { element },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }), [element]);

        return (
            <div
                ref={drag}
                className={`p-4 mb-4 bg-white rounded shadow cursor-grab flex flex-col items-center text-center justify-center transform transition-all duration-300 ease-in-out ${
                    isDragging ? 'opacity-50 scale-95' : 'opacity-100 hover:scale-105 hover:shadow-xl'
                }`}
            >
                {element.icon}
                <span className="mt-2 text-sm font-medium">{element.name}</span>
            </div>
        );
    };

    return (
        <div className="w-64 bg-[#E5E5E5] shadow-lg p-4 overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-m font-bold text-[#4B0082]">Elements</h2>
            </div>

            {/* Draggable Elements in Grid */}
            <div className="grid grid-cols-2 gap-4">
                {draggableElements.map((el, idx) => (
                    <DraggableElement key={idx} element={el} />
                ))}
            </div>
        </div>
    );
}

export default ElementSideBar;
