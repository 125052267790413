import React, { Component, createRef } from 'react';
import RichTextEditor, {
    BaseKit, Blockquote, Bold, BulletList, Code, CodeBlock, Color, ColumnActionButton, Emoji, 
    FontFamily, FontSize, Heading, Highlight,
    HorizontalRule, Indent, Italic, Katex, LineHeight,
    Link, Mention, MoreMark, OrderedList, SearchAndReplace, SlashCommand, Strike, Table, TableOfContents,
    TaskList, TextAlign, TextDirection, Underline,
} from 'reactjs-tiptap-editor';

import 'reactjs-tiptap-editor/style.css';

const extensions = [
    BaseKit.configure({
        placeholder: {
            showOnlyCurrent: true,
        },
        characterCount: false
    }),
    SearchAndReplace,
    TableOfContents,
    FontFamily,
    Heading.configure({ spacer: true }),
    FontSize,
    Bold,
    Italic,
    Underline,
    Strike,
    MoreMark,
    Katex,
    Emoji,
    Color.configure({ spacer: true }),
    Highlight,
    BulletList,
    OrderedList,
    TextAlign.configure({ types: ['heading', 'paragraph'], spacer: true }),
    Indent,
    LineHeight,
    TaskList.configure({
        spacer: true,
        taskItem: {
            nested: true,
        },
    }),
    Link,
    Blockquote,
    SlashCommand,
    HorizontalRule,
    Code.configure({
        toolbar: false,
    }),
    CodeBlock.configure({ defaultTheme: 'dracula' }),
    ColumnActionButton,
    Table,
    TextDirection,
    Mention,
];

class NotionEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: props?.selectedEditorContent || '', // Initialize with props content
        };
        this.editorRef = createRef(); // Create ref for RichTextEditor
        // this.debouncedSave = debounce(this.saveContent, 1000); // Save after 1 second of inactivity

    }

    // Lifecycle method for handling prop updates
    componentDidUpdate(prevProps) {
        // Check if the incoming props have changed
        if (JSON.stringify(prevProps.selectedEditorContent) !== JSON.stringify(this.props.selectedEditorContent)) {
            this.setState({
                content: this.props.selectedEditorContent || '',
            });

            // If RichTextEditor has setContent or resetContent methods, you can call it here
            if (this.editorRef.current?.setContent) {
                this.editorRef.current.setContent(this.props.selectedEditorContent || '');
            }
        }
    }

    // Handler for content change inside the editor
    handleContentChange = (value) => {
        this.setState({ content: value }); // Update local state
        this.props.handleContentChange(value); // Call parent handler to sync content
    };

    render() {

        return (
            <div className={`w-full max-w-[1000px] mt-0 p-3 flex flex-col`}>
                <RichTextEditor
                    key={this.props.index}
                    ref={this.editorRef} // Attach ref to the RichTextEditor
                    output="html"
                    content={this.props.selectedEditorContent} // Use the state content
                    hideToolbar={true}
                    onChangeContent={this.handleContentChange} // Pass the content change handler
                    extensions={extensions} // Your configured extensions
                    dark={false} // Assuming light theme, change as needed
                />
        </div>
        
        );
    }
}

export default NotionEditor;
