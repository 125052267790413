import React, { useState } from 'react';
import { FaRegFileAlt } from 'react-icons/fa';
import DocumentationList from '../documentation/documentationList';
import { Tooltip } from '@mui/material';
import utils from '../../common/utils'
import Zoom from '@mui/material/Zoom';
import { LuSettings2 } from "react-icons/lu";
import { RiLogoutBoxLine } from "react-icons/ri";
import { MdOutlineVideoLibrary } from "react-icons/md";


const DashboardLayout = () => {
    const [selectedMenu, setSelectedMenu] = useState('1');
    const userData = utils.getUserSessionData();

    const menuItems = [
        { id: '1', name: 'Documentation', icon: <FaRegFileAlt size={24} /> }, // File icon for documentation
        { id: '2', name: 'Video Tutorial', icon: <MdOutlineVideoLibrary size={24} /> },  // Video icon for tutorials
        { id: '3', name: 'Settings', icon: <LuSettings2 size={24} /> },  // Video icon for tutorials
        { id: '4', name: 'Logout', icon: <RiLogoutBoxLine size={24} /> },  // Video icon for tutorials
    ];

    const tooltipProps = {
        placement: "right",
        arrow: true,
        TransitionComponent: Zoom,
        componentsProps: {
            tooltip: {
                sx: {
                    backgroundColor: '#000',
                    color: 'white',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    fontSize: '14px',
                    padding: '8px 12px',
                }
            },
            arrow: {
                sx: {
                    color: '#000'
                }
            }
        }
    };

    return (
        <div className="min-h-screen flex bg-[#F5F5F5]">
            {/* Sidebar */}
            <div className="w-16 bg-[#E5E5E5] flex flex-col items-center py-2 space-y-4 shadow-lg"> {/* Reduced padding and space */}
                {/* SaaS Name */}
                <div className="items-center justify-center h-10 text-[#4B0082] font-bold text-lg mb-2"> {/* Reduced height and margin */}
                    NW
                </div>
                {/* Menu Items */}
                {menuItems.map((item, index) => (
                    <Tooltip
                        key={index}
                        title={<span className="text-m font-medium text-white">{item.name}</span>} // Larger, more readable text
                        {...tooltipProps}
                    >
                        <div onClick={() => setSelectedMenu(item.id)}
                            className={`text-[#4B0082] p-2 cursor-pointer rounded-[10px] flex items-center justify-center ${selectedMenu === item.id ? 'bg-[#4B0082] text-white' : ''}`} // Reduced padding
                        >
                            {item.icon}
                        </div>
                    </Tooltip>
                ))}
                <div className="flex-grow"></div>
                {/* User Avatar */}
                <Tooltip
                    title={<span className="text-m font-medium text-white">{userData?.firstName?.charAt(0).toUpperCase() + userData?.firstName?.slice(1)} {userData?.lastName?.charAt(0).toUpperCase() + userData?.lastName?.slice(1)}</span>}
                    {...tooltipProps}
                >
                    <div className="w-10 h-10 bg-[#4B0082] text-white flex items-center justify-center rounded-full cursor-pointer">
                        <span className="text-lg font-bold">{userData?.firstName?.charAt(0).toUpperCase()}</span>
                    </div>
                </Tooltip>
            </div>
            {/* Main Content Area */}
            <div className="flex-1 flex flex-col ml-6"> {/* Adjusted for Sidebar Space */}
                {/* Top Bar */}
                {/* <header className="h-10 flex justify-end items-center px-6">
                <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-2 bg-white text-[#4B0082] px-4 py-1 rounded-[40px] shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
                        <AiOutlineCreditCard className="text-[#4B0082] text-xl" />
                        <span className="text-lg font-semibold">Credits: <span className="text-[#3EB489]">10</span></span>
                    </div>
                </div>
            </header> */}

                {/* Main Content */}
                <main className="flex-1 p-4 m-4">
                    {selectedMenu === '1' && <DocumentationList />}
                </main>
            </div>
        </div>
    );
};

export default DashboardLayout;
