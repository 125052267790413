import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash, FaTimes, FaCheck } from 'react-icons/fa';
import * as Constants from '../../common/constants'
import { CircularProgress } from '@mui/material';
import { Modal, Box, Button, Typography } from '@mui/material';
import { FiFileText } from 'react-icons/fi';  // Importing React Icon

const Signup = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [conformPassword, setConformPassword] = useState("");
    const [passwordStarted, setPasswordStarted] = useState(false);
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [isTermConditionChecked, setIsTermConditopnChecked] = useState(false)
    const [error, setError] = useState();
    const [isSignupClick, setIsSignupClick] = useState(false);
    const [displayTremsCondition, setDisplayTremsCondition] = useState(false);

    useEffect(() => {
        if (error && !error?.success) {
            const timer = setTimeout(() => setError(), 5000); // Clear error after 5 seconds
            return () => clearTimeout(timer);
        }
    }, [error]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    // Password validation checks
    const passwordCriteria = [
        {
            label: "At least 8 characters",
            isValid: newPassword.length >= 8,
        },
        {
            label: "Contains an uppercase letter (A-Z)",
            isValid: /[A-Z]/.test(newPassword),
        },
        {
            label: "Contains a lowercase letter (a-z)",
            isValid: /[a-z]/.test(newPassword),
        },
        {
            label: "Contains a number (0-9)",
            isValid: /\d/.test(newPassword),
        },
        {
            label: "Contains a special character (!@#$%^&*)",
            isValid: /[!@#$%^&*]/.test(newPassword),
        },
    ];

    // Check if all criteria are met
    const isPasswordValid = passwordCriteria.every((criterion) => criterion.isValid);

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
        setPasswordStarted(true);
    };

    const handlePasswordBlur = () => {
        if (!newPassword) {
            setPasswordStarted(false); // Hide validation section if no password is entered
        }
    };

    const handleSignUpOnClick = () => {
        let newError = {}
        let isError = false;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!firstName) {
            newError.firstName = 'First name is required';
            isError = true
        } else if (!email) {
            newError.email = 'Email is required';
            isError = true
        } else if (!emailRegex.test(email)) {
            newError.email = 'Invalid Email address';
            isError = true
        } else if (!newPassword) {
            newError.password = 'Password is required';
            isError = true
        } else if (!isPasswordValid) {
            newError.password = 'Password does not meet the required criteria';
            isError = true;
        } else if (!conformPassword) {
            newError.cPassword = 'Confirm password is required';
            isError = true
        } else if (newPassword !== conformPassword) {
            newError.cPassword = 'Confirm password must match the password';
            isError = true
        } else if (!isTermConditionChecked) {
            newError.termsChecked = 'You must agree to the Terms and Conditions';
            isError = true
        }

        if (isError) {
            setError(newError);
        } else {
            setIsSignupClick(true)

            fetch(Constants.APIS.USER_SIGNUP, {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    password: newPassword
                })
            }).then(response => response.json())
                .then(data => {
                    setIsSignupClick(false)
                    if (data.responseCode === Constants.CODE_SUCCESS) {
                        setFirstName("")
                        setLastName("")
                        setEmail("")
                        setNewPassword("")
                        setConformPassword("")
                        setIsTermConditopnChecked(false)
                        setPasswordStarted(false);
                        setError({ success: data.responseMessage });
                        setTimeout(() => {
                            window.location.href = Constants.WEB_URLS.LOGIN;
                        }, 7000);

                    } else {
                        setError({ serverError: data.responseMessage });
                    }
                })
                .catch(err => {
                    setIsSignupClick(false)
                    setError({ serverError: "An error occurred during signup. Please try again." });
                });
        }
    }

    const handleTermsModalClose = (action) =>{
        if(action == 'accept'){
            setIsTermConditopnChecked(true)
        }else{
            setIsTermConditopnChecked(false)
        }
        setDisplayTremsCondition(false)
    }


    return (
        <div className="min-h-screen flex items-center justify-center bg-[#E5E5E5] p-4 font-sans">
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row items-center">
                    {/* Left column */}
                    <div className="md:w-1/2 text-center md:text-left flex flex-col justify-center mb-10 md:mb-0 p-8 text-gray-800 
                        animate-slideInLeft">
                        <h1 className="text-4xl font-bold leading-tight mb-5 animate-pulseSlow">
                            Welcome to <br />
                            <span className="text-[#4B0082]">Neverwrite</span>
                        </h1>
                        <p className="text-gray-700 text-lg mb-4">
                            Create product documentation instantly from your video recordings with the power of AI.
                        </p>
                        <p className="text-gray-700 text-lg">
                            Streamline your workflow, save time, and focus on what matters.
                            Neverwrite takes care of the documentation fast, easy, and tailored for your business.
                        </p>
                    </div>

                    {/* Right column - Signup form */}
                    <div className="w-[40%] m-5 bg-white rounded-lg shadow-lg p-10 animate-fadeIn" onKeyPress={(e) => e.key === 'Enter' && handleSignUpOnClick()}>
                        <h2 className="text-3xl font-bold text-center mb-6">Sign Up</h2>

                        {error?.serverError && (
                            <p className="text-red-500  mb-3 text-center animate-bounce transition-opacity duration-500 opacity-100">
                                {error.serverError}
                            </p>
                        )}
                        {error?.success && (
                            <p className="text-blue-500  mb-3 text-center  transition-opacity duration-500 opacity-100">
                                {error.success}
                            </p>
                        )}

                        {error?.firstName && (
                            <p className="text-red-500 animate-bounce transition-opacity duration-500 opacity-100">
                                {error.firstName}
                            </p>
                        )}
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <input
                                type="text"
                                value={firstName}
                                placeholder="First Name"
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#4B0082]"
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <input
                                type="text"
                                value={lastName}
                                placeholder="Last Name"
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#4B0082]"
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>

                        {error?.email && (
                            <p className="text-red-500 animate-bounce transition-opacity duration-500 opacity-100">
                                {error.email}
                            </p>
                        )}
                        <input
                            type="email"
                            value={email}
                            placeholder="Email"
                            className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#4B0082]"
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        {error?.password && (
                            <p className="text-red-500 animate-bounce transition-opacity duration-500 opacity-100">
                                {error.password}
                            </p>
                        )}
                        <div className="relative w-full mb-4">
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                value={newPassword}
                                onChange={handlePasswordChange}
                                onFocus={() => setPasswordStarted(true)}
                                onBlur={handlePasswordBlur} // Hide validation section on blur
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#4B0082]"
                            />
                            <div
                                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600 cursor-pointer"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </div>
                        </div>

                        {error?.cPassword && (
                            <p className="text-red-500 animate-bounce transition-opacity duration-500 opacity-100">
                                {error.cPassword}
                            </p>
                        )}
                        <div className="relative w-full mb-4">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                value={conformPassword}
                                placeholder="Confirm Password"
                                onChange={(e) => setConformPassword(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#4B0082]"
                            />
                            <div
                                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600 cursor-pointer"
                                onClick={toggleConfirmPasswordVisibility}
                            >
                                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                            </div>
                        </div>

                        {error?.termsChecked && (
                            <p className="text-red-500 animate-bounce transition-opacity duration-500 opacity-100">
                                {error.termsChecked}
                            </p>
                        )}
                        <div className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                checked={isTermConditionChecked} // Use checked instead of value
                                onChange={() => setIsTermConditopnChecked(!isTermConditionChecked)} // Change to onChange
                                id="terms"
                                className="mr-2"
                            />
                            <label htmlFor="terms" className="text-gray-700">
                                I agree to the <a onClick={() => setDisplayTremsCondition(true)} className="text-[#4B0082] hover:underline">Terms and Conditions</a>
                            </label>
                        </div>

                        <button
                            className={`w-[50%] mx-auto block bg-[#3EB489] text-white py-2 rounded  ${isSignupClick ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-[#32a078] transition duration-300'}`} // Change color when disabled
                            onClick={handleSignUpOnClick}
                            disabled={isSignupClick} // Disable button when loading
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} // Flexbox for centering content
                        >
                            {isSignupClick && (
                                <CircularProgress
                                    size={20} // Adjust size as needed
                                    color="inherit"
                                    style={{ marginRight: 8 }} // Space between spinner and text
                                />
                            )}
                            {isSignupClick ? 'Signing Up...' : 'Sign Up'} {/* Change text based on loading state */}
                        </button>

                        <div className="text-center mt-6">
                            <p className="text-gray-600">
                                Already have an account? <a onClick={() => { window.location.href = Constants.WEB_URLS.LOGIN }} className="text-[#4B0082] cursor-pointer">Login</a>
                            </p>
                        </div>

                        {/* Password Validation Criteria (Only show if password input is active and not valid yet) */}
                        {passwordStarted && !isPasswordValid && (
                            <div className="w-full p-4 mb-6">
                                <hr className="mb-3" />
                                <p className="text-gray-700 mb-4">
                                    Your password must contain at least:
                                </p>
                                {passwordCriteria.map((criterion, index) => (
                                    <div key={index} className="flex items-center mb-2">
                                        {criterion.isValid ? (
                                            <FaCheck className="text-green-600 mr-2" />
                                        ) : (
                                            <FaTimes className="text-red-600 mr-2" />
                                        )}
                                        <p
                                            className={`text-sm ${criterion.isValid ? "text-green-600" : "text-red-600"
                                                }`}
                                        >
                                            {criterion.label}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Modal
                open={displayTremsCondition}
                aria-labelledby="terms-of-service-title"
                aria-describedby="terms-of-service-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        bgcolor: 'white',
                        borderRadius: '16px',
                        boxShadow: 24,
                        p: 4,
                        maxWidth: '480px',
                        width: '100%',
                        outline: 'none',
                        textAlign: 'center',
                    }}
                >
                    {/* React Icon (Document Icon) */}
                    <FiFileText style={{ fontSize: '2rem', color: '#4B0082', marginBottom: '10px' }} />

                    {/* Title */}
                    <Typography
                        id="terms-of-service-title"
                        variant="h5"
                        component="h3"
                        className="font-bold text-indigo-600"
                    >
                        Terms & Conditions
                    </Typography>

                    {/* Main Text */}
                    <Typography
                        id="terms-of-service-description"
                        variant="body2"
                        color="textSecondary"
                        className="mt-2"
                    >
                        This summary is provided for convenience. Please review the Terms of Service below for important information regarding the legal conditions that apply to your use of <span className="font-semibold">NeverWrite</span>.
                    </Typography>

                    {/* Boxed Content Section */}
                    <Box
                        sx={{
                            bgcolor: '#F5F5F5',
                            borderRadius: '8px',
                            p: 2,
                            my: 4,
                            maxHeight: '250px',
                            overflowY: 'auto',
                            textAlign: 'left',
                        }}
                    >
                        <Typography variant="body2" color="textSecondary">
                            <strong>1. Acceptance of Terms</strong> <br />
                            By accessing and using <span className="font-semibold">NeverWrite</span>, you accept and agree to comply with these Terms. If you do not agree with any part of these terms, you must not use our tool.
                        </Typography>

                        <Typography variant="body2" color="textSecondary" mt={2}>
                            <strong>2. Changes to Terms</strong> <br />
                            We reserve the right to update these Terms at any time. Any changes will be effective immediately upon posting to our website or tool.
                        </Typography>

                        <Typography variant="body2" color="textSecondary" mt={2}>
                            <strong>3. User Responsibilities</strong> <br />
                            You are responsible for your use of <span className="font-semibold">NeverWrite</span> and for any content you generate using the tool. You agree not to misuse our service, including but not limited to violating any applicable laws, sharing harmful content, or infringing on the intellectual property rights of others.
                        </Typography>

                        <Typography variant="body2" color="textSecondary" mt={2}>
                            <strong>4. Intellectual Property</strong> <br />
                            All content generated by <span className="font-semibold">NeverWrite</span> is owned by you, the user. However, the platform itself, including design, code, and infrastructure, remains the property of <span className="font-semibold">NeverWrite</span>.
                        </Typography>

                        <Typography variant="body2" color="textSecondary" mt={2}>
                            <strong>5. Limitation of Liability</strong> <br />
                            <span className="font-semibold">NeverWrite</span> is provided "as is" without any guarantees or warranty. We are not liable for any damages or issues that may arise from the use or inability to use our tool.
                        </Typography>

                        <Typography variant="body2" color="textSecondary" mt={2}>
                            <strong>6. Email Policy</strong> <br />
                            Emails from <span className="font-semibold">NeverWrite</span> will be sent from support@neverwrite.in. Please ensure that this is the email address you are communicating with for support and service inquiries.
                        </Typography>

                        <Typography variant="body2" color="textSecondary" mt={2}>
                            <strong>7. Subscription Model</strong> <br />
                            Depending on your subscription, there may be limitations on the number of projects or services available to you on <span className="font-semibold">NeverWrite</span>. Details of these limitations are available on the subscription page.
                        </Typography>

                    </Box>

                    {/* Buttons */}
                    <Box display="flex" justifyContent="space-between">
                        <Button
                            variant="contained"
                            color="primary"
                              onClick={()=>handleTermsModalClose('accept')}
                            sx={{ borderRadius: '8px', bgcolor: '#4B0082' }}
                        >
                            Accept
                        </Button>
                        <Button
                            variant="outlined"
                              onClick={()=>handleTermsModalClose('decline')}
                            sx={{
                                borderRadius: '8px',
                                borderColor: '#4B0082',
                                color: '#4B0082',
                            }}
                        >
                            Decline
                        </Button>
                    </Box>
                </Box>
            </Modal>

        </div>
    );
};

export default Signup;
