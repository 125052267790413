import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from './pages/userAuth/login';
import Signup from './pages/userAuth/signup';
import EmailVerificationScreen from './pages/userAuth/emailVerficationScreen';
import ForgotPassword from './pages/userAuth/forgotPassword';
import NeverwriteLanding from './pages/landingPage/landing';
import DashboardLayout from './pages/dashboard/mainDashboard.';
import DocumentationEditor from './pages/documentation/documentationEditor';
import ResetPassword from './pages/userAuth/resetPassword';
import * as Constants from './common/constants'

// ProtectedRoute component
const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('userData');
  if (!isAuthenticated) {
    return <Navigate to={Constants.WEB_URLS.LOGIN} />;
  }
  return children;
};

const routesList = [
  { path: Constants.WEB_URLS.LOGIN, element: <Login /> },
  { path: Constants.WEB_URLS.SIGNUP, element: <Signup /> },
  { path: `${Constants.WEB_URLS.EMAIL_VERIFICATION}/:id`, element: <EmailVerificationScreen /> },
  { path: `${Constants.WEB_URLS.FORGOT_PASSWORD}`, element: <ForgotPassword /> },
  { path: `${Constants.WEB_URLS.RESET_PASSWORD}/:id`, element: <ResetPassword /> },
  { path: Constants.WEB_URLS.DASHBOARD, element: (<ProtectedRoute><DashboardLayout/></ProtectedRoute>) },
  { path: `${Constants.WEB_URLS.DOCUMENT_EDITOR}/:id`, element: <ProtectedRoute><DocumentationEditor /></ProtectedRoute>  },
  { path: "*", element: <Navigate to={Constants.WEB_URLS.DASHBOARD} /> }
]

const App = () => {
  return (
    <Router>
      <Routes>
        {routesList.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </Router>
  );
}

export default App;
