export const BASE_URL_Glob = process.env.REACT_APP_BASE_URL_API
// const BASE_URL = process.env.REACT_APP_BASE_URL_API
export const APIS = {
    USER_SIGNUP: BASE_URL_Glob+"signup",
    EMAIL_VERIFICATION: BASE_URL_Glob+"emailVerification",
    USER_LOGIN: BASE_URL_Glob+"login",
    FORGOT_PASSWORD: BASE_URL_Glob+"forgotPassword",
    RESET_PASSWORD: BASE_URL_Glob + "resetPassword",
    GET_DOCUMENTATION_PROJECTS: BASE_URL_Glob + "getDocumentationProjects",
    CREATE_DOCUMENTATION_PROJECT: BASE_URL_Glob + "createDocumentationProject",
    EDIT_DOCUMENTATION_PROJECT: BASE_URL_Glob + "editDocumentationProject",
    DELETE_DOCUMENTATION_PROJECT: BASE_URL_Glob + "deleteDocumentationProject",
    CREATE_DOCUSURAS_PROJECT: BASE_URL_Glob + "createDocusurasProject",
    GET_DOCUMENT_PROJECT_DETIALS: BASE_URL_Glob + "getDocumentProjectDetials",
    CREATE_DOCUMENTATION_EDITOR_FILE: BASE_URL_Glob + "createDocumentationEditorFile",
    DELETE_DOCUMENTATION_EDITOR_FILE: BASE_URL_Glob + "deleteDocumentationEditorFile",
    SAVE_FILE_CONTENT: BASE_URL_Glob + "saveFileContent",
    BUILD_DOCUSURAS_PROJECT: BASE_URL_Glob + "buildDocusurasProject",
    
}
export const WEB_URLS={
    ROOT:'/',
    LOGIN:'/',
    SIGNUP:"/signup",
    EMAIL_VERIFICATION:"/emailVerification",
    DASHBOARD:'/dashboard',
    FORGOT_PASSWORD:'/forgotpassword',
    RESET_PASSWORD:'/resetpassword',
    DOCUMENT_EDITOR:'/documenteditor'
}
export const NO_ACCESS_TO_USER = [15,1,16]
export const CODE_SUCCESS = 0;